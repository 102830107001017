<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
        <div class="top">
          <div class="top_left">当前位置:房屋缴费</div>
          <div class="top_right">
            <div class="button" @click="goHome">返回</div>
          </div>
        </div>
        <div class="foot">
          <div class="topTitleBox">
            <p>姓名: <span>{{tableData[0].ownerName}}</span></p>
            <p>身份证号:  <span>{{tableData[0].idCard}}</span></p>
            <p>手机号:  <span>{{tableData[0].phone}}</span></p>
          </div>
          <div class="head">
            <el-row class="elrow" justify="center">
              <el-col :span="4" class="elcol">小区名称</el-col>
              <el-col :span="2" class="elcol">楼栋号</el-col>
              <el-col :span="2" class="elcol">单元</el-col>
              <el-col :span="2" class="elcol">户室</el-col>
              <el-col :span="2" class="elcol">面积</el-col>
              <el-col :span="5" class="elcol">订单号</el-col>
              <el-col :span="2" class="elcol">订单金额</el-col>
              <el-col :span="2" class="elcol">操作</el-col>
            </el-row>
          </div>
          <div class="one">
            <el-rwo class="ct" v-for="(item,index) in tableData">
              <el-col :span="4" class="elcol">{{item.villageName}}</el-col>
              <el-col :span="2" class="elcol">{{ item.towerNum }}</el-col>
              <el-col :span="2" class="elcol">{{ item.unitName }}</el-col>
              <el-col :span="2" class="elcol">{{ item.houseNum }}</el-col>
              <el-col :span="2" class="elcol">{{ item.coveredarea }}</el-col>
              <el-col :span="5" class="elcol">{{ item.orderNo }}</el-col>
              <el-col :span="2" class="elcol">{{ item.orderAmt }}</el-col>
              <el-col :span="2" class="elcol">
                <el-button class="jf" @click="fkeyou(item.orderNo)">缴费</el-button>
              </el-col>
              <el-dialog
                class="fakeDialog"
                title="缴费确认"
                :visible.sync="dialogVisible"
                width="52%"
                :before-close="close"
                :show-close="false">
                <span>
                   <el-table :data="tableDatadetail" border  style="width: 100%" class="table_th"
                             :header-cell-style="{background:'#019B84',color:'#FFFFFF'}">
                    <el-table-column prop="villageName" label="小区名称" width="150" align="center"></el-table-column>
                    <el-table-column prop="towerNum" label="楼栋号" width="100" align="center"></el-table-column>
                    <el-table-column prop="unitName" label="单元" width="90" align="center"></el-table-column>
                    <el-table-column prop="houseNum" label="户室" width="90" align="center"></el-table-column>
                    <el-table-column prop="coveredarea" label="面积" width="100" align="center"></el-table-column>
                    <el-table-column prop="ownerName" label="姓名" width="90" align="center"></el-table-column>
                    <el-table-column prop="orderNo" label="订单号" width="250" align="center"></el-table-column>
                  </el-table>
                </span>
                <span slot="footer">
                  <span class="footer_one" style="color: #5D6891;font-size: 15px;font-weight: bold">应缴金额:
                    <span style="color: #F16D00;padding-left: 10px;font-size: 20px">{{totalMoney}}</span>
                  </span>
                  <el-button @click="dialogVisible = false" class="footer_first">返回</el-button>
                  <el-button type="primary" @click="gopay()" class="footer_second" >确认缴费</el-button>
                </span>
              </el-dialog>

              <el-dialog  class="fakeDialog  payContent"
                          title="请使用微信,支付宝,农行手机银行扫码缴费"
                          :visible.sync="ewmImageVisibl"
                          width="40%"
                          :before-close="false"
                          :show-close="true">
                  <div class="block" style="text-align: center">
                   <el-image :src="ewmsrc"></el-image>
                    <div class="payImgBox">
                      <img src="../assets/image/weixin.png" alt="">
                      <img src="../assets/image/zhifubao.png" alt="">
                      <img src="../assets/image/nonghang.png" alt="">
                    </div>
                 </div>
              </el-dialog>


            </el-rwo>
          </div>
        </div>
      </div>
    </my-fram>
  </div>

</template>

<script>
import MyFram from '@/components/myFram'
import axios from "axios";
export default {
  name: 'HousePayment',
  components: {MyFram},
  data() {
    return {
      dialogVisible: false,
      ewmImageVisibl:false,
      url: this.$Config.base_server,
      totalMoney:'',
      orderNo:'',
      ewmsrc:'',
      tableData: [],
      tableDatadetail: []
    }
  },
  filters:{
  },
  mounted() {
    var idCard= this.$utils.getIdcard();
    if (idCard==""){
      this.$alert("请将身份证放识别区","消息提示");
      return false;
    }
    this.$axios.post(this.url+'/QuerypayRecord?idCard='+idCard+"&stat="+2).then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;

      }else{
        this.$message.error('对不起,查询出错了');
      }
    })
  },

  methods: {

    goHome() {
      this.$router.push({path: '/'})
    },
    fkeyou(orderNo){
      this.$axios.post(this.url+'/QuerypayOrderByOrderNo?orderNo='+orderNo+"&stat="+2).then((res)=>{
        if (res.data.resp_code=='0'){
          this.dialogVisible=true;
          this.tableDatadetail=res.data.datas;
          this.totalMoney=res.data.datas[0].orderAmt;
          this.orderNo=res.data.datas[0].orderNo;
        }else{
          this.$message.error('对不起,查询出错了');
        }
      })
    },
    gopay(){
	  const orderNo=this.orderNo;
	  const totalMoney=this.totalMoney;
	  this.dialogVisible=false;
      this.ewmsrc=this.url+"/v1/api/abchina/ewmPay?dbKey=dx&orderNo="+orderNo+"&orderAmount="+totalMoney;
      this.ewmImageVisibl=true;
    }


  }
}
</script>

<style scoped lang="stylus">
.body_ {
  height: 100%
  width: 100%
}

.jf {
  background #019B84
  border: 0.0625rem solid #FFFFFF
  border-radius: 0.25rem
  margin-right: 0.625rem
  padding: 0.25rem 0.625rem
  color: #fff
  font-size 1.5rem
}
.topTitleBox{
  display flex
  align-items center
  text-indent 5rem
  background #019B84
  height 5rem
  border-top-left-radius: 1rem
  border-top-right-radius: 1rem
  p{
    margin-right 20px
    font-size 1.4rem
    color #fff
    font-weight 700
  }
}

.content {
  height: 100%
  width: 100%
  display: flex
  flex-direction: column

  .top {
    width: 100%
    height: 15%
    display: flex
    flex-direction row

    .top_left {
      width: 65%
      line-height: 100%
      font-size: 1.725rem
      display: flex
      flex-direction: column
      justify-content: center
      justify-items: center
      color: #FFFFFF
    }

    .top_right {
      width: 35%
      line-height: 100%
      font-size: 1.725rem
      display: flex
      flex-direction: row
      justify-content: right
      align-items: center

      .button {
        width: 5rem
        height: 2.5rem
        line-height: 2.4rem
        text-align: center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: 0.3rem
        font-size: 1.2rem
        color: #fff
        margin-right: 1rem
        corsor: pointer
      }
    }
  }

  .foot {
    width: 100%
    height: 85%

    .head {
     /* border-left: 1px solid #82D5FF80;
      border-top: 1px solid #82D5FF80;*/
      border-right: 1px solid #82D5FF80;
      height: 12%
      background: #019B84
      font-weight: 600
      font-size 0.9rem
      color: #FFFFFF

      .elrow {
        height 100%
        display flex
        flex-direction row
        align-items center
        justify-content space-between

        .elcol {
          text-align center
          font-size 1.4rem !important
        }
      }
    }

    .one {
      width 100%
      height 75%
      background #DBEEEC
      display flex
      flex-direction column
      border-bottom-left-radius 1rem
      border-bottom-right-radius 1rem
      border-left: 1px solid #82D5FF80
      border-bottom: 1px solid #82D5FF80
      border-right: 1px solid #82D5FF80

      .ct {
        height calc(90% / 7)
        font-size 1.2rem
        color #333333
        display flex
        flex-direction row
        align-items center
        border-bottom 1px solid #6d979f
        justify-content space-around
        .elcol {
          text-indent 0
          text-align center
          font-size 1.5rem
        }
      }
    }
  }
}
.table_th{

}
  .payContent{
    /deep/ .el-dialog{
      border 1px solid #6d979f !important
      /*background url("../assets/image/img.png") no-repeat top*/
      background #019B84 !important
    }

    /deep/ .el-dialog__title{
      color #fff
    }


    /deep/ .el-dialog__close{
      color #fff !important
      font-size 1.5rem
    }
  }


.fakeDialog{
  /deep/ .el-dialog__header{
    border-bottom 1px solid #e3e8ee
  }
  /deep/ .el-dialog__footer{
    padding 0
    height 70px
    background-color #DBDEEB
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
    .footer_one{
      display inline-block
      padding-right 520px

    }
    .footer_first{
      background-color #DBDEEB
    }
    .footer_second{
      background-image: linear-gradient(0deg, #FF7726 0%, #FFA66B 100%);
      width 100px
      height 70px
    }
  }

  .payImgBox{
    width 50%
    display flex
    align-items center
    justify-content space-around
    margin 0 auto
    margin-top 15px
    img{
      width 80px
    }
  }
}
</style>
